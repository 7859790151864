import React, { Component } from "react";
import Cards from "./Cards";
import "./Home.css";

export class NewHome extends Component {
  articles = [
    {
        "matching_id": 0,
        "similarity_score_image": 0.940247388,
        "product_code": "#106252",
        "Brand": "Babaton",
        "Product_URL": "https://www.aritzia.com/en/product/contour-v-neck-bodysuit/106252.html?dwvar_106252_color=1275",
        "product_category": "Bodysuits_All",
        "product_price": 48,
        "product_short_desc": "Body-hugging V-neck tank bodysuit",
        "product_FullDescription": "Wear some Contour. Designed for that perfect plunge, this is a sleeveless bodysuit with a thong-cut bottom and a double V neckline. It's made with Babaton's signature Contour luxe, ultra-flattering fabric coveted for its smoothing effect and second-skin feel. Essential for every body.\n\n\nPlease note, this item is final sale.\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\nAll bodysuits, hosiery, jewelry, all intimates, face masks and The Super Puff Personalized are final sale. Sale items marked at 50% off or greater are final sale. ",
        "product_features": "Snap closure, Double-layer fabric for supportive hold, Thong-cut bottom for seamless wear",
        "product_material": "Content: 94% polyamide, 6% elastane",
        "product_care": "Machine wash",
        "product_SizeFit": "Tight A close fit that hugs the body, Model is 5'10 178 cm wearing a size S",
        "product_AverageReviews": 4.0,
        "product_ReviewCounts": 5,
        "product_ReviewRange": 43.75,
        "ConsolidationReason": "Both products are from the Babaton brand and fall under the Bodysuits_All category, featuring a V-neck tank bodysuit design and made with similar materials (94% polyamide/nylon and 6% elastane).\nProduct 2 is a better choice due to its higher average review score of 4.6 and a larger number of reviews (207) compared to Product 1's average review score of 4.0 and 5 reviews.",
        "RecommendedPricing": "I recommend a price of $48 for the consolidated product, as both products are from the Babaton brand, have similar materials and design, and are currently priced at $48. The higher average review score and larger number of reviews for Product 2 support maintaining this price point for the consolidated product.",
        "image_url": "https://aritzia.scene7.com/is/image/Aritzia/s23_01_a28_106252_1275_on_a?wid=1200",
        "product_name": "Contour V-Neck Bodysuit"
    },
    {
        "matching_id": 0,
        "similarity_score_image": 0.940247388,
        "product_code": "#75719",
        "Brand": "Babaton",
        "Product_URL": "https://www.aritzia.com/en/product/contour-v-neck-bodysuit/75719.html?dwvar_75719_color=1275",
        "product_category": "Bodysuits_All",
        "product_price": 48,
        "product_short_desc": "V-neck tank bodysuit",
        "product_FullDescription": "Wear some Contour. This is a sleeveless bodysuit with a thong-cut bottom and double V neckline. It's made with Babaton's signature Contour luxe, ultra-flattering fabric coveted for its smoothing effect and second-skin feel. Essential for every body.\n\n\nPlease note, this item is final sale.\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\nAll bodysuits, hosiery, jewelry, all intimates, face masks and The Super Puff Personalized are final sale. Sale items marked at 50% off or greater are final sale. ",
        "product_features": "Double-layer fabric for supportive hold, Thong-cut bottom for seamless wear",
        "product_material": "Content: 94% nylon, 6% elastane",
        "product_care": "Machine wash",
        "product_SizeFit": "Tight A close fit that hugs the body, Model is 5'10.5 179 cm wearing a size S",
        "product_AverageReviews": 4.6,
        "product_ReviewCounts": 207,
        "product_ReviewRange": 46.00000083,
        "ConsolidationReason": "Both products are from the Babaton brand and fall under the Bodysuits_All category, featuring a V-neck tank bodysuit design and made with similar materials (94% polyamide/nylon and 6% elastane).\nProduct 2 is a better choice due to its higher average review score of 4.6 and a larger number of reviews (207) compared to Product 1's average review score of 4.0 and 5 reviews.",
        "RecommendedPricing": "I recommend a price of $48 for the consolidated product, as both products are from the Babaton brand, have similar materials and design, and are currently priced at $48. The higher average review score and larger number of reviews for Product 2 support maintaining this price point for the consolidated product.",
        "image_url": "https://aritzia.scene7.com/is/image/Aritzia/s22_01_a01_75719_1275_on_a?wid=1200",
        "product_name": "Contour V-Neck Bodysuit"
    },
    {
        "matching_id": 1,
        "similarity_score_image": 0.905670584,
        "product_code": "#108399",
        "Brand": "Sunday Best",
        "Product_URL": "https://www.aritzia.com/en/product/kaia-top/108399.html?dwvar_108399_color=1275",
        "product_category": "Short Sleeves",
        "product_price": 38,
        "product_short_desc": "Puff-sleeve top",
        "product_FullDescription": "This a tie-front top with puff sleeves and a peplum hem. It's made with soft 100% cotton jersey.",
        "product_features": "-",
        "product_material": "Content: 100% cotton",
        "product_care": "Machine wash",
        "product_SizeFit": "Slim Streamlined to fit close to the body, Length: Waist Intended to hit between the waist and high hip, Model is 5'7 170 cm wearing a size S",
        "product_AverageReviews": 4.1,
        "product_ReviewCounts": 9,
        "product_ReviewRange": 70.74999809,
        "ConsolidationReason": "Both products are from the same brand, Sunday Best, and belong to the Short Sleeves category, featuring puff-sleeve tops made with 100% cotton material.\nProduct 2 is a better choice as it has the same average review score of 4.1 but with a higher review count (15) and a review range closer to true to size.",
        "RecommendedPricing": "I recommend a price of $38 for the consolidated product. Both products are from the same brand, Sunday Best, with similar materials and features, and their current prices are already at $38, which reflects the brand's reputation for stylish and well-fitting basics.",
        "image_url": "https://aritzia.scene7.com/is/image/Aritzia/s23_02_a01_108399_1275_on_a?wid=1200",
        "product_name": "Kaia Top"
    },
    {
        "matching_id": 1,
        "similarity_score_image": 0.905670584,
        "product_code": "#96887",
        "Brand": "Sunday Best",
        "Product_URL": "https://www.aritzia.com/en/product/kaia-top/96887.html?dwvar_96887_color=1275",
        "product_category": "Short Sleeves",
        "product_price": 38,
        "product_short_desc": "Puff-sleeve top",
        "product_FullDescription": "This a puff-sleeve, tie-front top with a peplum hem. It's made with soft 100% combed-cotton jersey.",
        "product_features": "-",
        "product_material": "Content: 100% cotton",
        "product_care": "Machine wash",
        "product_SizeFit": "Slim Streamlined to fit close to the body, Length: Waist Intended to hit between the waist and high hip, Model is 5'7 170 cm wearing a size S",
        "product_AverageReviews": 4.1,
        "product_ReviewCounts": 15,
        "product_ReviewRange": 50.0,
        "ConsolidationReason": "Both products are from the same brand, Sunday Best, and belong to the Short Sleeves category, featuring puff-sleeve tops made with 100% cotton material.\nProduct 2 is a better choice as it has the same average review score of 4.1 but with a higher review count (15) and a review range closer to true to size.",
        "RecommendedPricing": "I recommend a price of $38 for the consolidated product. Both products are from the same brand, Sunday Best, with similar materials and features, and their current prices are already at $38, which reflects the brand's reputation for stylish and well-fitting basics.",
        "image_url": "https://aritzia.scene7.com/is/image/Aritzia/s23_02_a01_96887_1275_on_a?wid=1200",
        "product_name": "Kaia Top"
    },
    {
        "matching_id": 3,
        "similarity_score_image": 0.901570846,
        "product_code": "#95779",
        "Brand": "Babaton",
        "Product_URL": "https://www.aritzia.com/en/product/everyday-t-shirt/95779.html?dwvar_95779_color=1275",
        "product_category": "Short Sleeves,Full Length Tops",
        "product_price": 35,
        "product_short_desc": "Jersey crewneck t-shirt",
        "product_FullDescription": "This is a t-shirt with a crew neckline. It's made with Relux Jersey, an ultra-soft and drapey fabric that uses TENCEL Lyocell responsibly sourced wood-based fibres produced through a closed-loop system that reduces environmental impact.",
        "product_features": "-",
        "product_material": "Content: 90% TENCEL Lyocell, 10% elastane",
        "product_care": "Machine wash",
        "product_SizeFit": "Classic Follows your contours with a little room, Length: Waist Intended to hit between the waist and high hip, Model is 5'11.5 181 cm wearing a size S",
        "product_AverageReviews": 4.2,
        "product_ReviewCounts": 58,
        "product_ReviewRange": 42.50000119,
        "ConsolidationReason": "Both products are similar as they belong to the 'Short Sleeves' category, have crewneck designs, and are from different brands (Babaton and The Group by Babaton). The materials used are also quite similar, with both having a blend of elastane.\nProduct 1 is a better choice due to its average review score of 4.2 (58 reviews). Product 2 has a average review score of 5.0 with only 1 review.",
        "RecommendedPricing": "I recommend a price of $35 for the consolidated product, as both products have the same original price and are from different brands with similar materials, design, and target audience. The higher review score of Product 2 supports maintaining the current price point.",
        "image_url": "https://aritzia.scene7.com/is/image/Aritzia/hi-res/f23_01_a01_95779_7325_off_a.jpg",
        "product_name": "Everyday T-Shirt"
    },
    {
        "matching_id": 3,
        "similarity_score_image": 0.901570846,
        "product_code": "#111764",
        "Brand": "The Group by Babaton",
        "Product_URL": "https://www.aritzia.com/en/product/everyday-t-shirt/111764.html?dwvar_111764_color=7325",
        "product_category": "Short Sleeves",
        "product_price": 35,
        "product_short_desc": "Crew-neck t-shirt",
        "product_FullDescription": "This is a crew-neck t-shirt designed for a classic fit. It's made with drapey, stretchy micro-rib fabric.",
        "product_features": "-",
        "product_material": "Content: 95% modal, 5% elastane",
        "product_care": "Machine wash",
        "product_SizeFit": "Classic Follows your contours with a little room, Length: Waist Intended to hit between the waist and high hip, Model is 5'10 178 cm wearing a size S",
        "product_AverageReviews": 5.0,
        "product_ReviewCounts": 1,
        "product_ReviewRange": 0.0,
        "ConsolidationReason": "Both products are similar as they belong to the 'Short Sleeves' category, have crewneck designs, and are from different brands (Babaton and The Group by Babaton). The materials used are also quite similar, with both having a blend of elastane.\nProduct 1 is a better choice due to its average review score of 4.2 (58 reviews). Product 2 has a average review score of 5.0 with only 1 review.",
        "RecommendedPricing": "I recommend a price of $35 for the consolidated product, as both products have the same original price and are from different brands with similar materials, design, and target audience. The higher review score of Product 2 supports maintaining the current price point.",
        "image_url": "https://aritzia.scene7.com/is/image/Aritzia/hi-res/s23_00_a01_111764_7325_off_a.jpg",
        "product_name": "Everyday T-Shirt"
    },
    {
        "matching_id": 6,
        "similarity_score_image": 0.939802235,
        "product_code": "#103935",
        "Brand": "Wilfred",
        "Product_URL": "https://www.aritzia.com/en/product/only-waist-turtleneck/103935.html?dwvar_103935_color=1275",
        "product_category": "Long Sleeves,Turtlenecks",
        "product_price": 38,
        "product_short_desc": "Ribbed turtleneck",
        "product_FullDescription": "This is a turtleneck designed for a body-hugging fit. It's made with Relux Rib, an ultra-soft and drapey ribbed fabric that uses TENCEL Modal responsibly sourced wood-based fibres produced through a process that reduces impact on forests, biodiversity and water supply.",
        "product_features": "-",
        "product_material": "Content: 91% TENCEL Modal, 9% elastane",
        "product_care": "Machine wash",
        "product_SizeFit": "Tight A close fit that hugs the body, Length: Waist Intended to hit between the waist and high hip, Model is 5'10 178 cm wearing a size S",
        "product_AverageReviews": 4.1,
        "product_ReviewCounts": 13,
        "product_ReviewRange": 31.25,
        "ConsolidationReason": "Both products are similar as they belong to the same category of long-sleeve turtlenecks, made with TENCEL Modal fabric, and designed for a tight fit. The brands are different, with product 1 from Wilfred and product 2 from Sunday Best.\nProduct 1 is slightly better as it has the same average review score of 4.1 but with more reviews (13) compared to product 2 (8 reviews), indicating higher customer satisfaction.",
        "RecommendedPricing": "I recommend a price of $39 for the consolidated product. This price is a midpoint between the two products' prices, considering their similarity in material, fit, and customer satisfaction, while also taking into account the slight difference in brand reputation.",
        "image_url": "https://aritzia.scene7.com/is/image/Aritzia/f22_04_a01_103935_1275_on_a?wid=1200",
        "product_name": "Only Waist Turtleneck"
    },
    {
        "matching_id": 6,
        "similarity_score_image": 0.939802235,
        "product_code": "#90087",
        "Brand": "Sunday Best",
        "Product_URL": "https://www.aritzia.com/en/product/freshman-waist-turtleneck/90087.html?dwvar_90087_color=1275",
        "product_category": "Long Sleeves,Turtlenecks",
        "product_price": 40,
        "product_short_desc": "Ribbed longsleeve turtleneck",
        "product_FullDescription": "This is a longsleeve turtleneck designed for a tight fit. It's made with ReRIB, a soft and drapey ribbed fabric that uses TENCEL Modal responsibly sourced wood-based fibres produced through a process that reduces impact on forests, biodiversity and water supply.",
        "product_features": "-",
        "product_material": "Content: 90% TENCEL Modal, 10% elastane",
        "product_care": "Machine wash",
        "product_SizeFit": "Tight A close fit that hugs the body, Length: Waist Intended to hit between the waist and high hip, Model is 5'9 175 cm wearing a size S",
        "product_AverageReviews": 4.1,
        "product_ReviewCounts": 8,
        "product_ReviewRange": 50.0,
        "ConsolidationReason": "Both products are similar as they belong to the same category of long-sleeve turtlenecks, made with TENCEL Modal fabric, and designed for a tight fit. The brands are different, with product 1 from Wilfred and product 2 from Sunday Best.\nProduct 1 is slightly better as it has the same average review score of 4.1 but with more reviews (13) compared to product 2 (8 reviews), indicating higher customer satisfaction.",
        "RecommendedPricing": "I recommend a price of $39 for the consolidated product. This price is a midpoint between the two products' prices, considering their similarity in material, fit, and customer satisfaction, while also taking into account the slight difference in brand reputation.",
        "image_url": "https://aritzia.scene7.com/is/image/Aritzia/f22_02_a01_90087_1275_on_a?wid=1200",
        "product_name": "Freshman Waist Turtleneck"
    },
    {
        "matching_id": 8,
        "similarity_score_image": 0.891577971,
        "product_code": "#107343",
        "Brand": "Sunday Best",
        "Product_URL": "https://www.aritzia.com/en/product/seamless-scoopneck-bra-top/107343.html?dwvar_107343_color=1275",
        "product_category": "Cropped Tops,Bra Tops",
        "product_price": 28,
        "product_short_desc": "Seamless bra top",
        "product_FullDescription": "This is a seamless bra top with a scoop neckline and adjustable straps. It's made from a stretchy fabric with a barely there feel.\n\n\nPlease note, this item is final sale.\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\nAll bodysuits, hosiery, jewelry, all intimates, face masks and The Super Puff Personalized are final sale. Sale items marked at 50% off or greater are final sale. ",
        "product_features": "-",
        "product_material": "Content: 92% nylon, 8% elastane",
        "product_care": "Machine wash",
        "product_SizeFit": "Tight A close fit that hugs the body, Length: Ribcage Intended to hit below the chest', Model is 5'9 176 cm wearing a size S",
        "product_AverageReviews": 2.9,
        "product_ReviewCounts": 7,
        "product_ReviewRange": 25.0,
        "ConsolidationReason": "Both products are similar as they belong to the same categories (Cropped Tops, Bra Tops), have the same material composition (92% nylon, 8% elastane), and are from different brands (Sunday Best and Talula).\nThe Talula Seamless Scoop-Neck Bra Top is a better choice due to its higher average review score (3.8) and more customer reviews (64) compared to the Sunday Best Seamless Bra Top (2.9 average review score and 7 reviews).",
        "RecommendedPricing": "I recommend a price of $28 for the consolidated product, as both products have the same price, similar material composition, and belong to the same categories, while the Talula Seamless Scoop-Neck Bra Top has a higher average review score and more customer reviews, indicating higher customer satisfaction.",
        "image_url": "https://aritzia.scene7.com/is/image/Aritzia/s23_02_a01_107343_1275_on_a?wid=1200",
        "product_name": "Seamless Scoopneck Bra Top"
    },
    {
        "matching_id": 8,
        "similarity_score_image": 0.891577971,
        "product_code": "#84848",
        "Brand": "Talula",
        "Product_URL": "https://www.aritzia.com/en/product/seamless-bra-top/84848.html?dwvar_84848_color=1275",
        "product_category": "Cropped Tops,Bra Tops",
        "product_price": 28,
        "product_short_desc": "Seamless scoop-neck bra top",
        "product_FullDescription": "This is a seamless scoop-neck bra top with adjustable straps and a low, open back. It's made from stretchy ribbed fabric specially knit for a barely-there feel.\u00c3\u201a\u00c2\u00a0\n\n\nPlease note, this item is final sale.\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\nAll bodysuits, hosiery, jewelry, all intimates, face masks and The Super Puff Personalized are final sale. Sale items marked at 50% off or greater are final sale. ",
        "product_features": "-",
        "product_material": "Content: 92% nylon, 8% elastane",
        "product_care": "Machine wash",
        "product_SizeFit": "Medium A classic fit with moderate coverage, Medium A versatile, balanced fit with medium support, Model is 5'11 180 cm wearing a size S",
        "product_AverageReviews": 3.8,
        "product_ReviewCounts": 64,
        "product_ReviewRange": 37.00000048,
        "ConsolidationReason": "Both products are similar as they belong to the same categories (Cropped Tops, Bra Tops), have the same material composition (92% nylon, 8% elastane), and are from different brands (Sunday Best and Talula).\nThe Talula Seamless Scoop-Neck Bra Top is a better choice due to its higher average review score (3.8) and more customer reviews (64) compared to the Sunday Best Seamless Bra Top (2.9 average review score and 7 reviews).",
        "RecommendedPricing": "I recommend a price of $28 for the consolidated product, as both products have the same price, similar material composition, and belong to the same categories, while the Talula Seamless Scoop-Neck Bra Top has a higher average review score and more customer reviews, indicating higher customer satisfaction.",
        "image_url": "https://aritzia.scene7.com/is/image/Aritzia/hi-res/f22_15_a09_84848_1275_on_b.jpg",
        "product_name": "Seamless Bra Top"
    },
    {
        "matching_id": 11,
        "similarity_score_image": 0.949099215,
        "product_code": "#109113",
        "Brand": "Babaton",
        "Product_URL": "https://www.aritzia.com/en/product/contour-cami-bodysuit/109113.html?dwvar_109113_color=1275",
        "product_category": "Bodysuits_All",
        "product_price": 48,
        "product_short_desc": "Square-neck cami bodysuit",
        "product_FullDescription": "This is a cami bodysuit with a square neckline, double-layer fabric and a thong-cut bottom. It's made with Babaton's signature Contour luxe, ultra-flattering fabric coveted for its smoothing effect and second-skin feel. Essential for every body.\n\n\nPlease note, this item is final sale.\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\nAll bodysuits, hosiery, jewelry, all intimates, face masks and The Super Puff Personalized are final sale. Sale items marked at 50% off or greater are final sale. ",
        "product_features": "Snap closure, Adjustable straps, Double-layer fabric, Thong-cut bottom for seamless wear",
        "product_material": "Content: 94% nylon, 6% elastane; Lining: 94% nylon, 6% elastane",
        "product_care": "Machine wash",
        "product_SizeFit": "Tight A close fit that hugs the body, Model is 5'7 172 cm wearing a size S",
        "product_AverageReviews": 4.8,
        "product_ReviewCounts": 5,
        "product_ReviewRange": 58.24999809,
        "ConsolidationReason": "Both products are from the Babaton brand and fall under the Bodysuits_All category, featuring square-neck cami bodysuits made with the same material composition (94% nylon, 6% elastane).\nProduct 1 is a better choice due to its higher average review score of 4.8 compared to Product 2's 4.1, despite having fewer total reviews.",
        "RecommendedPricing": "I recommend a price of $48 for the consolidated product, as both products are from the Babaton brand, have the same material composition, and fall under the Bodysuits_All category, with Product 1 having a higher average review score of 4.8 compared to Product 2's 4.1.",
        "image_url": "https://aritzia.scene7.com/is/image/Aritzia/s23_01_a01_109113_1275_on_a?wid=1200",
        "product_name": "Contour Cami Bodysuit"
    },
    {
        "matching_id": 11,
        "similarity_score_image": 0.949099215,
        "product_code": "#81856",
        "Brand": "Babaton",
        "Product_URL": "https://www.aritzia.com/en/product/contour-cami-bodysuit/81856.html?dwvar_81856_color=1275",
        "product_category": "Bodysuits_All",
        "product_price": 48,
        "product_short_desc": "Square-neck cami bodysuit",
        "product_FullDescription": "This is a cami bodysuit with a square neckline and a thong-cut bottom. It's made with Babaton's signature Contour luxe, ultra-flattering fabric coveted for its smoothing effect and second-skin feel. Essential for every body.\n\n\nPlease note, this item is final sale.\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\nAll bodysuits, hosiery, jewelry, all intimates, face masks and The Super Puff Personalized are final sale. Sale items marked at 50% off or greater are final sale. ",
        "product_features": "Adjustable straps, Double-layered body, Thong-cut bottom for seamless wear",
        "product_material": "Content: 94% nylon, 6% elastane",
        "product_care": "Machine wash",
        "product_SizeFit": "Tight A close fit that hugs the body, Model is 5'6 168 cm wearing a size S",
        "product_AverageReviews": 4.1,
        "product_ReviewCounts": 36,
        "product_ReviewRange": 50.99999905,
        "ConsolidationReason": "Both products are from the Babaton brand and fall under the Bodysuits_All category, featuring square-neck cami bodysuits made with the same material composition (94% nylon, 6% elastane).\nProduct 1 is a better choice due to its higher average review score of 4.8 compared to Product 2's 4.1, despite having fewer total reviews.",
        "RecommendedPricing": "I recommend a price of $48 for the consolidated product, as both products are from the Babaton brand, have the same material composition, and fall under the Bodysuits_All category, with Product 1 having a higher average review score of 4.8 compared to Product 2's 4.1.",
        "image_url": "https://aritzia.scene7.com/is/image/Aritzia/s22_01_a01_81856_1275_on_a?wid=1200",
        "product_name": "Contour Cami Bodysuit"
    },
    {
        "matching_id": 12,
        "similarity_score_image": 0.85981359,
        "product_code": "#104984",
        "Brand": "Sunday Best",
        "Product_URL": "https://www.aritzia.com/en/product/sinchseamless%E2%84%A2-willow-cropped-t-shirt/104984.html?dwvar_104984_color=1275",
        "product_category": "Short Sleeves,Cropped Tops",
        "product_price": 30,
        "product_short_desc": "Seamless cropped t-shirt",
        "product_FullDescription": "Or stand. This is a crewneck t-shirt with seamless construction and a tight fit. It's jersey-knit with SinchSeamless a stretchy fabric with a second-skin feel and body-hugging fit.",
        "product_features": "-",
        "product_material": "Content: 94% nylon, 6% elastane",
        "product_care": "Machine wash",
        "product_SizeFit": "Tight A close fit that hugs the body, Length: Cropped Intended to hit around the ribcage, Model is 5'10 178 cm wearing a size S",
        "product_AverageReviews": 4.5,
        "product_ReviewCounts": 4,
        "product_ReviewRange": 50.0,
        "ConsolidationReason": "Both products are similar in category, material, and fit, as they are both white, short-sleeved cropped tops made from 94% nylon and 6% elastane with a tight fit.\nThe Babaton Contour Crew Cropped T-Shirt is a better choice due to its slightly higher average review score (4.6) and more customer reviews (62), indicating higher customer satisfaction.",
        "RecommendedPricing": "I recommend a price of $39 for the consolidated product. This price is a midpoint between the Sunday Best ($30) and Babaton ($48) products, considering their similarities in material, fit, and category, while also taking into account the slightly higher customer satisfaction and reputation of the Babaton brand.",
        "image_url": "https://aritzia.scene7.com/is/image/Aritzia/f23_02_a01_104984_1275_on_a?wid=1200",
        "product_name": "Sinchseamless Cropped T-Shirt"
    },
    {
        "matching_id": 12,
        "similarity_score_image": 0.85981359,
        "product_code": "#83840",
        "Brand": "Babaton",
        "Product_URL": "https://www.aritzia.com/en/product/contour-crew-cropped-t-shirt/83840.html?dwvar_83840_color=1275",
        "product_category": "Short Sleeves,Cropped Tops",
        "product_price": 48,
        "product_short_desc": "Cropped crewneck t-shirt",
        "product_FullDescription": "A real crowd pleaser. This is a cropped t-shirt with a crew neckline and double-layer fabric for a supportive fit. It's made with Babaton's signature Contour luxe, ultra-flattering fabric coveted for its smoothing effect and second-skin feel. Essential for every body.",
        "product_features": "Double-layer body, Single-layer sleeves",
        "product_material": "Content: 94% nylon, 6% elastane",
        "product_care": "Machine wash",
        "product_SizeFit": "Tight A close fit that hugs the body, Length: Cropped Intended to hit around the ribcage, Model is 5'11 180 cm wearing a size S",
        "product_AverageReviews": 4.6,
        "product_ReviewCounts": 62,
        "product_ReviewRange": 43.00000072,
        "ConsolidationReason": "Both products are similar in category, material, and fit, as they are both white, short-sleeved cropped tops made from 94% nylon and 6% elastane with a tight fit.\nThe Babaton Contour Crew Cropped T-Shirt is a better choice due to its slightly higher average review score (4.6) and more customer reviews (62), indicating higher customer satisfaction.",
        "RecommendedPricing": "I recommend a price of $39 for the consolidated product. This price is a midpoint between the Sunday Best ($30) and Babaton ($48) products, considering their similarities in material, fit, and category, while also taking into account the slightly higher customer satisfaction and reputation of the Babaton brand.",
        "image_url": "https://aritzia.scene7.com/is/image/Aritzia/f23_01_a01_83840_1275_on_a?wid=1200",
        "product_name": "Contour Crew Cropped T-Shirt"
    },
    {
        "matching_id": 5,
        "similarity_score_image": 0.872772369,
        "product_code": "#76783",
        "Brand": "The Group by Babaton",
        "Product_URL": "https://www.aritzia.com/en/product/foundation-crew-t-shirt/76783.html?dwvar_76783_color=1275",
        "product_category": "Short Sleeves,Full Length Tops",
        "product_price": 35,
        "product_short_desc": "Pima cotton crew-neck t-shirt",
        "product_FullDescription": "Like, really soft. This is a crewneck t-shirt with a rounded hem and a slim fit. It's cut from Foundation Jersey, exceptionally soft and drapey Peruvian Pima Cotton made with TENCEL Modal responsibly sourced wood-based fibres produced through a process that reduces impact on forests, biodiversity and water supply.",
        "product_features": "-",
        "product_material": "Content: 46% cotton, 46% TENCEL Modal, 8% elastane",
        "product_care": "Machine wash",
        "product_SizeFit": "Slim Streamlined to fit close to the body, Length: Hip Intended to hit at or below the hip, Model is 5'11.5 181 cm wearing a size S",
        "product_AverageReviews": 4.4,
        "product_ReviewCounts": 129,
        "product_ReviewRange": 42.75000095,
        "ConsolidationReason": "Both products are similar in category (Short Sleeves) and material (46% cotton, 46% TENCEL Modal, 8% elastane), but they come from different brands: The Group by Babaton and TnAction.\nProduct 1 is a better choice based on its average review score of 4.4 across 129 reviews as compared to Product 2 with average review score of 5, having only 1 reviewer.",
        "RecommendedPricing": "I recommend a price of $37.50 for the consolidated clothing product. This price is the average of the two products' prices ($35 and $40), considering their similarity in category, material, and high customer satisfaction, while also taking into account the differences in brand reputation and product fit.",
        "image_url": "https://aritzia.scene7.com/is/image/Aritzia/f23_00_a01_76783_1275_off_a?wid=1200",
        "product_name": "Foundation Crew T-Shirt"
    },
    {
        "matching_id": 5,
        "similarity_score_image": 0.872772369,
        "product_code": "#111623",
        "Brand": "TnAction",
        "Product_URL": "https://www.aritzia.com/en/product/foundation-quo-t-shirt/111623.html?dwvar_111623_color=1275",
        "product_category": "Short Sleeves",
        "product_price": 40,
        "product_short_desc": "Pima cotton crewneck t-shirt",
        "product_FullDescription": "This is a crewneck t-shirt with a tight fit. It's cut from Foundation Jersey, exceptionally soft and drapey Peruvian Pima Cotton made with TENCEL Modal responsibly sourced wood-based fibres produced through a process that reduces impact on forests, biodiversity and water supply.",
        "product_features": "Spiro at back",
        "product_material": "Content: 46% pima cotton, 46% TENCEL Modal, 8% elastane",
        "product_care": "Machine Wash",
        "product_SizeFit": "Tight A close fit that hugs the body, Length: Waist Intended to hit between the waist and high hip, Model is 5'9 176 cm wearing a size XL",
        "product_AverageReviews": 5.0,
        "product_ReviewCounts": 1,
        "product_ReviewRange": 25.0,
        "ConsolidationReason": "Both products are similar in category (Short Sleeves) and material (46% cotton, 46% TENCEL Modal, 8% elastane), but they come from different brands: The Group by Babaton and TnAction.\nProduct 1 is a better choice based on its average review score of 4.4 across 129 reviews as compared to Product 2 with average review score of 5, having only 1 reviewer.",
        "RecommendedPricing": "I recommend a price of $37.50 for the consolidated clothing product. This price is the average of the two products' prices ($35 and $40), considering their similarity in category, material, and high customer satisfaction, while also taking into account the differences in brand reputation and product fit.",
        "image_url": "https://aritzia.scene7.com/is/image/Aritzia/f23_19_a01_111623_1275_off_a?wid=1200",
        "product_name": "Foundation Quo T-Shirt"
    },
    {
        "matching_id": 7,
        "similarity_score_image": 0.907539322,
        "product_code": "#80296",
        "Brand": "Wilfred Free",
        "Product_URL": "https://www.aritzia.com/en/product/weekend-t-shirt/80296.html?dwvar_80296_color=1275",
        "product_category": "Short Sleeves,Cropped Tops",
        "product_price": 30,
        "product_short_desc": "Cotton crewneck t-shirt",
        "product_FullDescription": "This is a crewneck t-shirt designed for a classic fit. It's made from soft 100% combed-cotton jersey.\u00c3\u201a\u00c2\u00a0\nGarment Dye (Gd) Colours:\nThe unique colour of our garment dye is achieved through a special process instead of dyeing the fabric, we dye the finished garment for a dimensional, washed-down look and soft feel. Results vary from one piece to the next, so yours is truly one-of-a-kind.",
        "product_features": "Ribbed neckline",
        "product_material": "Content: 100% cotton",
        "product_care": "Machine wash. Garment Dye (Gd) Colours: Colour will change with washing. Wash separately before wearing.",
        "product_SizeFit": "Classic Follows your contours with a little room, Length: Waist Intended to hit between the waist and high hip, Model is 5'9 176 cm wearing a size S",
        "product_AverageReviews": 4.6,
        "product_ReviewCounts": 123,
        "product_ReviewRange": 44.99999881,
        "ConsolidationReason": "The reason for consolidation between the two products is that they both belong to the Short Sleeves category, are made of 100% cotton material, and are from different brands, Wilfred Free and Sunday Best.\nThe better product out of these two is the Wilfred Free Cotton Crewneck T-shirt (#80296) as it has a higher average review score of 4.6 compared to the Sunday Best Cotton Crewneck T-shirt (#100788) with an average review score of 4.0.",
        "RecommendedPricing": "I recommend a price of $30 for the consolidated product, as both the Wilfred Free Cotton Crewneck T-shirt (#80296) and Sunday Best Cotton Crewneck T-shirt (#100788) are priced at $30, and the Wilfred Free T-shirt has a higher average review score of 4.6 compared to the Sunday Best T-shirt with a score of 4.0.",
        "image_url": "https://aritzia.scene7.com/is/image/Aritzia/hi-res/f23_07_a01_80296_1275_off_a.jpg",
        "product_name": "Weekend T-Shirt"
    },
    {
        "matching_id": 7,
        "similarity_score_image": 0.907539322,
        "product_code": "#100788",
        "Brand": "Sunday Best",
        "Product_URL": "https://www.aritzia.com/en/product/dream-t-shirt/100788.html?dwvar_100788_color=1275",
        "product_category": "Short Sleeves",
        "product_price": 30,
        "product_short_desc": "Cotton crewneck t-shirt",
        "product_FullDescription": "This is a t-shirt with a ribbed crew neckline. It's crafted with soft jersey made from 100% cotton.\nGarment Dye (Gd) Colours:\nThe unique colour of our garment dye is achieved through a special process instead of dyeing the fabric, we dye the finished garment for a dimensional, washed-down look and soft feel. Results vary from one piece to the next, so yours is truly one-of-a-kind.",
        "product_features": "-",
        "product_material": "Content: 100% cotton",
        "product_care": "Machine wash. Garment Dye (Gd) Colours: Colour will change with washing. Wash separately before wearing.",
        "product_SizeFit": "Classic Follows your contours with a little room, Length: Waist Intended to hit between the waist and high hip, Model is 5'9.5 177 cm wearing a size S",
        "product_AverageReviews": 4.0,
        "product_ReviewCounts": 8,
        "product_ReviewRange": 50.0,
        "ConsolidationReason": "The reason for consolidation between the two products is that they both belong to the Short Sleeves category, are made of 100% cotton material, and are from different brands, Wilfred Free and Sunday Best.\nThe better product out of these two is the Wilfred Free Cotton Crewneck T-shirt (#80296) as it has a higher average review score of 4.6 compared to the Sunday Best Cotton Crewneck T-shirt (#100788) with an average review score of 4.0.",
        "RecommendedPricing": "I recommend a price of $30 for the consolidated product, as both the Wilfred Free Cotton Crewneck T-shirt (#80296) and Sunday Best Cotton Crewneck T-shirt (#100788) are priced at $30, and the Wilfred Free T-shirt has a higher average review score of 4.6 compared to the Sunday Best T-shirt with a score of 4.0.",
        "image_url": "https://aritzia.scene7.com/is/image/Aritzia/hi-res/s23_02_a01_100788_1275_off_a.jpg",
        "product_name": "Dream T-Shirt"
    }
]

  constructor() {
    super();
    this.state = {
      articles: this.articles,
      displayedArticles: 2,
      loading: false,
      disablePrev: true,
      disableNext: false,
      currentIndex: 0,
    };
  }

  componentDidMount() {
    const initialArticles = this.articles.slice(
      0,
      this.state.displayedArticles
    );
    this.setState({ articles: initialArticles });
  }

  handlePrevClick = () => {
    const { displayedArticles } = this.state;
    const newIndex = Math.max(displayedArticles - 2, 0);

    const prevArticles = this.articles.slice(newIndex, newIndex + 2);

    this.setState({
      displayedArticles: newIndex,
      articles: prevArticles,
      disableNext: false,
      disablePrev: newIndex === 0,
    });
  };

  handleNextClick = () => {
    const { displayedArticles } = this.state;
    const newIndex = displayedArticles + 2;
    let nextState = false;
    if (newIndex === this.articles.length) {
      nextState = true;
    }
    const nextArticles = this.articles.slice(displayedArticles, newIndex);

    this.setState({
      displayedArticles: newIndex,
      disablePrev: false,
      articles: nextArticles,
      disableNext: nextState,
      setCurrentCardIndex: 2,
    });
  };

  previousCard = () => {
    this.setState((prevState) => ({
      currentIndex: Math.max(prevState.currentIndex - 2, 0),
    }));
  };

  nextCard = () => {
    this.setState((prevState) => ({
      currentIndex: Math.min(
        prevState.currentIndex + 2,
        this.articles.length - 2
      ),
    }));
  };

  showConsolidationReason = (index) => {
    return index % 2 === 0;
  };

  showRecommendedPricing = (index) => {
    return index % 2 === 0;
  };

  render() {
    const displayedArticles = this.state.articles.slice(0, this.state.displayedArticles);
    const { currentIndex } = this.state;
    return (
      <div className="main-container d-flex px-20 py-20">
        <div className="card-container">
          <h3 className=" main-title px-4 py-2 mb-4">
            <img src="../../Aritzia-Logo(1).png" alt="" style={{ width: "150px" }}/>
          </h3>

          <h4 style={{textAlign: "center"}}>
            Product Consolidation Recommendations
          </h4>
          <div className="row" style={{ gap: "10px" }}>
            <div className="col-md-5">
              <button
                disabled={currentIndex === 0}
                type="button"
                class="btn btn-sm btn-dark"
                onClick={this.previousCard}
              >
                <strong>&larr;</strong>
              </button>
            </div>
            <div className="nextBtn col-md-5">
              <button disabled={currentIndex >= this.articles.length - 2}
                type="button"
                className="btn btn-sm btn-dark"
                onClick={this.nextCard}
              >
                <strong>&rarr;</strong>
              </button>
            </div>
            {this.articles.slice(currentIndex, currentIndex + 2).map((element, index) => {
                return (
                  <div
                    className="col-md-5 col-sm-12"
                    key={element.product_code}
                  >
                    <Cards
                      product_name={element.product_name}
                      product_FullDescription={element.product_FullDescription}
                      product_code={element.product_code}
                      Brand={element.Brand}
                      product_category={element.product_category}
                      product_price={element.product_price}
                      product_AverageReviews={element.product_AverageReviews}
                      image_url={element.image_url}
                      ProductCode=""
                      product_features={element.product_features}
                      product_material={element.product_material}
                      product_care={element.product_care}
                      product_SizeFit={element.product_SizeFit}
                      product_ReviewCounts={element.product_ReviewCounts}
                      showConsolidationReason={this.showConsolidationReason(index)}
                      ConsolidationReason={element.ConsolidationReason}
                      showRecommendedPricing={this.showRecommendedPricing(index)}
                      RecommendedPricing={element.RecommendedPricing}
                      displayedArticles={displayedArticles}
                    />
                  </div>
                );
              })}
          </div>
        </div>

        <div className="suggestion-container">
          <h3 className="px-4 py-2">
            <img src="../../genAI-logo.gif" alt="" style={{ width: "40px" }} />
            &nbsp;Gen AI Insights
          </h3>
          <div className="consolidation-container">
            <h4 className="consolidation-reason mb-3">Consolidation Reason</h4>
            <div className="mb-5">
              <ul>
                {this.articles[currentIndex].ConsolidationReason.split("\n").map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>

            <h4 className="recommended-price mb-3">Recommended Price</h4>
            <ul>
              <li>{this.articles[currentIndex].RecommendedPricing}</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default NewHome;
