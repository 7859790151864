import './App.css';
import NewHome from './components/newHome';

function App() {
  return (
    <div className="App">
      <NewHome/>
    </div>
  );
}

export default App;
