import React, { Component } from "react";
import './Cards.css'

export class Cards extends Component {
    componentDidMount() {
        this.adjustDescriptionMinHeight();
        window.addEventListener('resize', this.adjustDescriptionMinHeight);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.adjustDescriptionMinHeight);
    }

    // adjustDescriptionMinHeight = () => {
    //     const { displayedArticles } = this.props;
    //     let maxHeight = 0;

    //     // Loop through the displayed articles to find the maximum description height
    //     displayedArticles.forEach((element, index) => {
    //         const description = document.getElementById(`card-description-${index}`);
    //         if (description) {
    //             const descriptionHeight = description.offsetHeight;
    //             if (descriptionHeight > maxHeight) {
    //                 maxHeight = descriptionHeight;
    //             }
    //         }
    //     });

    //     // Set the min-height for all card descriptions
    //     displayedArticles.forEach((element, index) => {
    //         const description = document.getElementById(`card-description-${index}`);
    //         if (description) {
    //             description.style.minHeight = `${maxHeight}px`;
    //         }
    //     });
    // }

    adjustDescriptionMinHeight = () => {
        const { displayedArticles } = this.props;
        let maxHeight = 0;
        let maxSizeFitHeight = 0;
    
        displayedArticles.forEach((element, index) => {
            const description = document.getElementById(`card-description-${index}`);
            if (description) {
                const descriptionHeight = description.offsetHeight;
                if (descriptionHeight > maxHeight) {
                    maxHeight = descriptionHeight;
                }
            }
    
            const sizeFit = document.getElementById(`card-sizefit-${index}`);
            if (sizeFit) {
                const sizeFitHeight = sizeFit.offsetHeight;
                if (sizeFitHeight > maxSizeFitHeight) {
                    maxSizeFitHeight = sizeFitHeight;
                }
            }
        });
    
        displayedArticles.forEach((element, index) => {
            const description = document.getElementById(`card-description-${index}`);
            if (description) {
                description.style.minHeight = `${maxHeight}px`;
            }
    
            const sizeFit = document.getElementById(`card-sizefit-${index}`);
            if (sizeFit) {
                sizeFit.style.minHeight = `${maxSizeFitHeight}px`;
            }
        });
    }
    

    render() {
        let { product_code, Brand, product_category, product_price, product_SizeFit, product_name, product_FullDescription, image_url, product_AverageReviews, product_features, product_material, product_care, product_ReviewCounts, displayedArticles } = this.props;
        return (
            <div className="main-container my-3">
                <div className="card-body">
                    <img src={image_url} className="card-img" alt="..." />
                    <h5 className="card-title">{product_name}</h5>
                    <p className="card-code"><strong>Product Code:</strong> {product_code}</p>
                    <p className="card-price"><strong>Price:</strong> ${product_price}</p>
                    <p className="card-review"><strong>Rating:</strong> {product_AverageReviews}</p>
                    <p className="card-review-count"><strong>Reviews Count:</strong> {product_ReviewCounts}</p>
                    <p className="card-brand"><strong>Brand:</strong> {Brand}</p>
                    <p className="card-category"><strong>Product Category:</strong> {product_category}</p>
                    <p className="card-details"><strong>Product Details</strong></p>
                    <ul className="features"><strong>Features:</strong>
                        <li>{product_features}</li>
                    </ul>
                    <ul className="material"><strong>Material:</strong>
                        <li>{product_material}</li>
                    </ul>
                    <ul className="care"><strong>Care:</strong>
                        <li>{product_care}</li>
                    </ul>
                    <p id={`card-sizefit-${displayedArticles}`} className="card-sizefit"><strong>Fit:</strong> {product_SizeFit}</p>
                    <p><strong>Description</strong></p>
                    <p id={`card-description-${displayedArticles}`} className="card-description">{product_FullDescription}</p>
                </div>
            </div>
        );
    }
}

export default Cards;

